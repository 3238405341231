import React, { useEffect, useState } from "react";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { getQuestionsBlocTypes } from "redux/slices/questionsBlocTypes";
import { useDispatch, useSelector } from "react-redux";
import findQuestions from "helpers/findQuestions";

export default function FilterQuestions({ setQuestions }) {
  const { questionBlocks } = useSelector((state) => state.questionBlocks);
  const { questions } = useSelector((state) => state.questions);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getQuestionsBlocTypes(""));
  }, [dispatch]);
  const { questionBlocTypes } = useSelector((state) => state.questionBlocTypes);

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel id="language">Questions By Question Blocks</InputLabel>
      <Select
        labelId="Questions"
        id="qtn"
        name="qtnId"
        label="Questions By Question Blocks"
        defaultValue="All"
        onChange={(e) => {
          findQuestions(
            questions,
            questionBlocks,
            e.target.value,
            setQuestions
          );
        }}
      >
        <MenuItem key={18} value={"All"}>
          All
        </MenuItem>
        {questionBlocTypes.map((el, index) => (
          <MenuItem key={index} value={el?.code}>
            {el.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
