import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getData,
  deleteOne,
  createOne,
  getOne,
  updateOne,
} from "../../services/api";
import { serialize } from "object-to-formdata";

export const getRevisions = createAsyncThunk(
  "revision/getrevision",
  async (filter, { getState, rejectWithValue }) => {
    try {
      const { data } = await getData("revisions", filter);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);
// get one rev
export const getRevision = createAsyncThunk(
  "revision/getRev",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await getOne("revisions", id);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);
// delete rev
export const deleteRevision = createAsyncThunk(
  "revision/deleteRev",
  async (id, { rejectWithValue }) => {
    try {
      await deleteOne("revisions", id);
      return id;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

// insert rev
export const insertRevision = createAsyncThunk(
  "revisions/insertRev",
  async (revision, { rejectWithValue, thunkAPI }) => {
    try {
      const { data } = await createOne(
        "revisions",
        serialize(revision, { indices: true })
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

// update rev
export const updateRevision = createAsyncThunk(
  "revisions/updateRev",
  async (data, { rejectWithValue, thunkAPI }) => {
    const { id, ...val } = data;
    // console.log(data[0]);
    try {
      const { data } = await updateOne(
        "revisions",
        id,
        serialize(val, { indices: true })
      );
      console.log(data);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const initialState = {
  revisions: [],
  revision: {},
  error: false,
  loading: "",
};

const revisionsSlice = createSlice({
  name: "revisions",
  initialState,
  reducers: {},
  extraReducers: {
    // get rev
    [getRevisions.pending]: (state, { payload }) => {
      state.loading = "loading";
    },
    [getRevisions.fulfilled]: (state, { payload }) => {
      state.loading = "success";
      state.revisions = payload.payload.revisions;
    },
    [getRevisions.rejected]: (state, { payload }) => {
      state.error = payload;
      state.loading = "failed";
    },
    // update rev
    [updateRevision.pending]: (state, { payload }) => {
      state.loading = "loading";
    },
    [updateRevision.fulfilled]: (state, { payload }) => {
      state.loading = "success";
      // state.revisions = payload.payload.revisions;
    },
    [updateRevision.rejected]: (state, { payload }) => {
      state.error = payload;
      state.loading = "failed";
    },
    // get one revision
    [getRevision.pending]: (state, { payload }) => {
      state.loading = "loading";
    },
    [getRevision.fulfilled]: (state, { payload }) => {
      state.loading = "success";
      state.revision = payload.payload.revision;
    },
    [getRevision.rejected]: (state, { payload }) => {
      state.error = payload;
      state.loading = "failed";
    },
    //insert revision
    [insertRevision.pending]: (state, { payload }) => {
      state.loading = "loading";
    },
    [insertRevision.fulfilled]: (state, { payload }) => {
      state.loading = "success";
      state.revisions.push(payload.payload.revisions);
    },
    [insertRevision.rejected]: (state, { payload }) => {
      state.error = true;
      state.loading = "failed";
    },
    // delete revision
    [deleteRevision.fulfilled]: (state, { payload }) => {
      state.loading = "success";
      const revs = state.revisions.filter((el) => el._id !== payload);
      state.revisions = revs;
    },
    [deleteRevision.rejected]: (state, { payload }) => {
      state.error = payload;
      state.loading = "failed";
    },
  },
});

// export const { checkOrder } = levelsSlice.actions;
export default revisionsSlice.reducer;
