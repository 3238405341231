import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getData,
  deleteOne,
  createOne,
  getOne,
  updateOne,
} from "../../services/api";
import { serialize } from "object-to-formdata";

export const getBadges = createAsyncThunk(
  "badge/getbdgs",
  async (filter, { getState, rejectWithValue }) => {
    try {
      const { data } = await getData("badges", filter);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);
// get one badge
export const getBadge = createAsyncThunk(
  "badges/getBdg",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await getOne("badges", id);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);
// delete badge
export const deleteBadge = createAsyncThunk(
  "badges/deleteBdg",
  async (id, { rejectWithValue }) => {
    try {
      await deleteOne("badges", id);
      return id;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

// insert badge
export const createBadge = createAsyncThunk(
  "badges/insertBdg",
  async (revision, { rejectWithValue, thunkAPI }) => {
    try {
      const { data } = await createOne(
        "badges",
        serialize(revision, { indices: true })
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

// update badge
export const updateBadge = createAsyncThunk(
  "badges/updateBdg",
  async (data, { rejectWithValue, thunkAPI }) => {
    const { id, ...val } = data;
    try {
      const { data } = await updateOne(
        "badges",
        id,
        serialize(val, { indices: true })
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const initialState = {
  badges: [],
  badge: {},
  error: false,
  loading: "",
};

const badgesSlice = createSlice({
  name: "badges",
  initialState,
  reducers: {},
  extraReducers: {
    // get badge
    [getBadges.pending]: (state, { payload }) => {
      state.loading = "loading";
    },
    [getBadges.fulfilled]: (state, { payload }) => {
      state.loading = "success";
      state.badges = payload.payload.badges;
    },
    [getBadges.rejected]: (state, { payload }) => {
      state.error = payload;
      state.loading = "failed";
    },
    // update bdg
    [updateBadge.pending]: (state, { payload }) => {
      state.loading = "loading";
    },
    [updateBadge.fulfilled]: (state, { payload }) => {
      state.loading = "success";
    },
    [updateBadge.rejected]: (state, { payload }) => {
      state.error = payload;
      state.loading = "failed";
    },
    // get one bdg
    [getBadge.pending]: (state, { payload }) => {
      state.loading = "loading";
    },
    [getBadge.fulfilled]: (state, { payload }) => {
      state.loading = "success";
      state.badge = payload.payload.badge;
    },
    [getBadge.rejected]: (state, { payload }) => {
      state.error = payload;
      state.loading = "failed";
    },
    //insert bdg
    [createBadge.pending]: (state, { payload }) => {
      state.loading = "loading";
    },
    [createBadge.fulfilled]: (state, { payload }) => {
      state.loading = "success";
      state.badges.push(payload.payload.badges);
    },
    [createBadge.rejected]: (state, { payload }) => {
      state.error = true;
      state.loading = "failed";
    },
    // delete bdg
    [deleteBadge.fulfilled]: (state, { payload }) => {
      state.loading = "success";
      const bdgs = state.badges.filter((el) => el._id !== payload);
      state.badges = bdgs;
    },
    [deleteBadge.rejected]: (state, { payload }) => {
      state.error = payload;
      state.loading = "failed";
    },
  },
});

export default badgesSlice.reducer;
