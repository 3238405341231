import React, { Fragment, lazy, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import MinimalLayout from "./layouts/Minimal";
import MainLayout from "./layouts/Main";
import LoadingScreen from "./components/LoadingScreen";
import AuthGuard from "./components/AuthGuard";
import GuestGuard from "./components/GuestGuard";
import { useStore } from "./contexts/JWTAuthContext";
import { checkPermission } from "./utils/acl/aclRouter";
import { Dashboard } from "./views";
import LoginView from "./views/LoginView";
import { Helmet } from "react-helmet";

export const RenderRoutes = ({ routes }) => {
  const { user, isAuthenticated } = useStore();
  // console.log("user", user);
  // console.log(isAuthenticated);
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;
          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={(props) => (
                <Layout>
                  <Helmet>
                    <title>{route.title}</title>
                  </Helmet>
                  {/* {route.routes ? (
                    <RenderRoutes routes={route.routes} />
                  ) : (
                    <Component {...props} />
                  )} */}
                  {route.routes ? (
                    <RenderRoutes routes={route.routes} />
                  ) : user &&
                    checkPermission(route.path, ["ROLE_SUPER_ADMIN"], []) ? (
                    <Component {...props} />
                  ) : !user && !isAuthenticated ? (
                    <LoginView />
                  ) : (
                    <Dashboard />
                  )}
                </Layout>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  );
};

const routes = [
  {
    exact: true,
    layout: MinimalLayout,
    path: "/404",
    component: lazy(() => import("./views/NotFound")),
  },
  {
    exact: true,
    layout: MinimalLayout,
    guard: GuestGuard,
    path: "/login",
    component: lazy(() => import("./views/LoginView")),
  },
  {
    path: "/",
    guard: AuthGuard,
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: "/",
        component: lazy(() => import("./views/Dashboard")),
      },
      {
        exact: true,
        path: "/account",
        component: lazy(() => import("./views/Account")),
        title: "TA|Account",
      },
      //users
      {
        exact: true,
        path: "/users/students",
        component: lazy(() => import("./views/User/UserList")),
        title: "TA|Students",
      },
      {
        exact: true,
        path: "/users/students/create",
        component: lazy(() => import("./views/User/UserCreate")),
        title: "TA|Students-create",
      },
      {
        exact: true,
        path: "/users/students/edit/:id",
        component: lazy(() => import("./views/User/UserEdit")),
        title: "TA|Students-edit",
      },
      {
        exact: true,
        path: "/users/students/view/:id",
        component: lazy(() => import("./views/User/UserShow")),
        title: "TA|Students-view",
      },
      // admins
      {
        exact: true,
        path: "/users/admins",
        component: lazy(() => import("./views/Admins/AdminList")),
        title: "TA|Admins",
      },
      {
        exact: true,
        path: "/users/admins/create",
        component: lazy(() => import("./views/Admins/AdminCreate")),
        title: "TA|Admin-create",
      },
      {
        exact: true,
        path: "/users/admins/edit/:id",
        component: lazy(() => import("./views/Admins/AdminEdit")),
        title: "TA|Admin-edit",
      },
      {
        exact: true,
        path: "/users/admins/view/:id",
        component: lazy(() => import("./views/Admins/AdminShow")),
        title: "TA|Admins-view",
      },
      //levels
      {
        exact: true,
        path: "/contents/levels",
        component: lazy(() => import("./views/Level/LevelList")),
        title: "TA|Contents-levels",
      },
      {
        exact: true,
        path: "/contents/levels/create",
        component: lazy(() => import("./views/Level/LevelCreate")),
        title: "TA|Contents-levels-create",
      },
      {
        exact: true,
        path: "/contents/levels/edit/:id",
        component: lazy(() => import("./views/Level/LevelEdit")),
        title: "TA|Contents-levels-edit",
      },
      {
        exact: true,
        path: "/orders/levels",
        component: lazy(() => import("./views/Orders/Levels/LevelOrder")),
        title: "TA|Contents-levels-orders",
      },

      //languages
      {
        exact: true,
        path: "/contents/languages",
        component: lazy(() => import("./views/Language/LanguageList")),
        title: "TA|Contents-languages",
      },
      {
        exact: true,
        path: "/contents/languages/create",
        component: lazy(() => import("./views/Language/LanguageCreate")),
        title: "TA|Contents-languages-create",
      },
      {
        exact: true,
        path: "/contents/languages/edit/:id",
        component: lazy(() => import("./views/Language/LanguageEdit")),
        title: "TA|Contents-languages-edit",
      },
      // {
      //   exact: true,
      //   path: "/contents/languages/view/:id",
      //   component: lazy(() => import("./views/Language/LanguageShow")),
      //   title: "TA|Contents-languages-edit",
      // },
      // courses
      {
        exact: true,
        path: "/contents/courses",
        component: lazy(() => import("./views/Course/CourseList")),
        title: "TA|Contents-courses",
      },
      {
        exact: true,
        path: "/contents/courses/create",
        component: lazy(() => import("./views/Course/CourseCreate")),
        title: "TA|Contents-courses-create",
      },
      {
        exact: true,
        path: "/contents/courses/edit/:id",
        component: lazy(() => import("./views/Course/CourseEdit")),
        title: "TA|Contents-courses-edit",
      },
      //lessons
      {
        exact: true,
        path: "/contents/lessons",
        component: lazy(() => import("./views/Lesson/LessonList")),
        title: "TA|Contents-lessons",
      },
      {
        exact: true,
        path: "/contents/lessons/create",
        component: lazy(() => import("./views/Lesson/LessonCreate")),
        title: "TA|Contents-lessons-create",
      },
      {
        exact: true,
        path: "/contents/lessons/edit/:id",
        component: lazy(() => import("./views/Lesson/LessonEdit")),
        title: "TA|Contents-lessons-edit",
      },
      {
        exact: true,
        path: "/contents/lessons/view/:id",
        component: lazy(() => import("./views/Lesson/LessonShow")),
        title: "TA|Contents-lessons-view",
      },
      {
        exact: true,
        path: "/orders/lessons",
        component: lazy(() => import("./views/Orders/Lesson/LessonOrder")),
        title: "TA|Contents-lessons-orders",
      },
      //exercices
      {
        exact: true,
        path: "/contents/exercises",
        component: lazy(() => import("./views/Exercise/ExerciseList")),
        title: "TA|Contents-exercises",
      },
      {
        exact: true,
        path: "/contents/exercises/create",
        component: lazy(() => import("./views/Exercise/ExerciseCreate")),
        title: "TA|Contents-exercises-create",
      },
      {
        exact: true,
        path: "/contents/exercises/edit/:id",
        component: lazy(() => import("./views/Exercise/ExerciseEdit")),
        title: "TA|Contents-exercises-edit",
      },
      // {
      //   exact: true,
      //   path: "/contents/exercises/view/:id",
      //   component: lazy(() => import("./views/Lesson/LessonShow")),
      //   title: "TA|Contents-exercices-view",
      // },
      {
        exact: true,
        path: "/orders/exercises",
        component: lazy(() => import("./views/Orders/Exercises/ExerciseOrder")),
        title: "TA|Contents-exercises-orders",
      },
      //questions
      {
        exact: true,
        path: "/contents/questions",
        component: lazy(() => import("./views/Question/QuestionList")),
        title: "TA|Contents-questions",
      },
      {
        exact: true,
        path: "/contents/questions/create",
        component: lazy(() => import("./views/Question/QuestionCreate")),
        title: "TA|Contents-questions-create",
      },

      {
        exact: true,
        path: "/contents/questions/edit/:id",
        component: lazy(() => import("./views/Question/QuestionEdit")),
        title: "TA|Contents-questions-edit",
      },
      {
        exact: true,
        path: "/orders/questions",
        component: lazy(() => import("./views/Orders/Questions/QuestionOrder")),
        title: "TA|Contents-questions-orders",
      },
      // notif
      {
        exact: true,
        path: "/contents/notifications",
        component: lazy(() => import("./views/Notifications/NotificationList")),
        title: "TA|Contents-notifications",
      },
      {
        exact: true,
        path: "/contents/notifications/create",
        component: lazy(() =>
          import("./views/Notifications/NotificationCreate")
        ),
        title: "TA|Contents-notifications",
      },
      // keywords
      {
        exact: true,
        path: "/contents/keywords",
        component: lazy(() => import("./views/Keywords/KeywordList")),
        title: "TA|Contents-keywords",
      },
      {
        exact: true,
        path: "/contents/keywords/create",
        component: lazy(() => import("./views/Keywords/KeywordCreate")),
        title: "TA|Contents-keywords-create",
      },
      {
        exact: true,
        path: "/contents/keywords/edit/:id",
        component: lazy(() => import("./views/Keywords/KeywordEdit")),
        title: "TA|Contents-keywords-edit",
      },
      // {
      //   exact: true,
      //   path: "/contents/questions/view/:id",
      //   component: lazy(() => import("./views/Question/QuestionShow")),
      //   title: "TA|Contents-questions-view",
      // },

      // revisions
      {
        exact: true,
        path: "/contents/revisions",
        component: lazy(() => import("./views/Revisions/RevisionList")),
        title: "TA|Contents-keywords",
      },
      {
        exact: true,
        path: "/contents/revisions/create",
        component: lazy(() => import("./views/Revisions/RevisionCreate")),
        title: "TA|Contents-revisions-create",
      },
      {
        exact: true,
        path: "/contents/revisions/edit/:id",
        component: lazy(() => import("./views/Revisions/RevisionEdit")),
        title: "TA|Contents-revisions-edit",
      },

      /////question type
      {
        exact: true,
        path: "/contents/questions-types",
        component: lazy(() =>
          import("./views/QuestionsTypes/QuestionTypeList")
        ),
        title: "TA|Contents-questions-types",
      },
      {
        exact: true,
        path: "/contents/questions-types/create",
        component: lazy(() =>
          import("./views/QuestionsTypes/QuestionTypeCreate")
        ),
        title: "TA|Contents-questions-types-create",
      },
      {
        exact: true,
        path: "/contents/questions-types/edit/:id",
        component: lazy(() =>
          import("./views/QuestionsTypes/QuestionTypeEdit")
        ),
        title: "TA|Contents-questions-types-edit",
      },

      /////badges
      {
        exact: true,
        path: "/contents/badges",
        component: lazy(() => import("./views/Badges/BadgeList")),
        title: "TA|Contents-badges",
      },
      {
        exact: true,
        path: "/contents/badges/create",
        component: lazy(() => import("./views/Badges/BadgeCreate")),
        title: "TA|Contents-badges-create",
      },
      {
        exact: true,
        path: "/contents/badges/edit/:id",
        component: lazy(() => import("./views/Badges/BadgeEdit")),
        title: "TA|Contents-revisions-edit",
      },

      //exercice type
      {
        exact: true,
        path: "/contents/exercises-types",
        component: lazy(() => import("./views/Exercise-type/ExerciseTypeList")),
        title: "TA|Contents-exercises-types",
      },
      {
        exact: true,
        path: "/contents/exercises-types/create",
        component: lazy(() =>
          import("./views/Exercise-type/ExerciseTypeCreate")
        ),
        title: "TA|Contents-exercises-types-create",
      },
      {
        exact: true,
        path: "/contents/exercises-types/edit/:id",
        component: lazy(() => import("./views/Exercise-type/ExerciseTypeEdit")),
        title: "TA|Contents-exercises-types-edit",
      },
      //dashboard
      {
        exact: true,
        path: "/dashboard",
        component: () => <Redirect to="/" />,
        title: "TA|Dashboard",
      },
      {
        exact: true,
        path: "/**/",
        component: () => <Redirect to="/404" />,
        title: "TA|Not found",
      },
      {
        exact: true,
        path: "/404",
        component: lazy(() => import("./views/NotFound/NotFound")),
        title: "TA|Not found",
      },
    ],
  },
  {
    path: "*",
    layout: MinimalLayout,
    routes: [
      // {
      //   exact: true,
      //   path: '/',
      //   component: HomeView
      // },
      {
        component: () => <Redirect to="/404" />,
        title: "TA|Not found",
      },
    ],
  },
];

export default routes;
