// // @flow
// import React, { useEffect, useState } from "react";
// import { makeStyles } from "@material-ui/styles";
// import {
//   Card,
//   CardContent,
//   CardHeader,
//   Divider,
//   Grid,
//   IconButton,
//   TextField,
// } from "@material-ui/core";
// import clsx from "clsx";
// import { useFormik } from "formik";
// import * as Yup from "yup";
// import { useParams } from "react-router-dom";
// import Tab from "@material-ui/core/Tab";
// import TabPanel from "@material-ui/lab/TabPanel";
// import TabContext from "@material-ui/lab/TabContext";
// import TabList from "@material-ui/lab/TabList";
// import AccountCircleIcon from "@material-ui/icons/AccountCircle";
// import CardActions from "@material-ui/core/CardActions";
// import Button from "@material-ui/core/Button";
// import SnackBar from "../../../components/SnackBar";
// import PropTypes from "prop-types";
// import { useDispatch, useSelector } from "react-redux";
// import { createUser, getAllUsers } from "../../../redux/slices/users";
// import { useHistory } from "react-router-dom";
// const useStyles = makeStyles((theme) => ({
//   root: {
//     width: "100%",
//     "& > * + *": {
//       marginTop: theme.spacing(2),
//       flexGrow: 1,
//       backgroundColor: theme.palette.background.paper,
//     },
//   },
//   content: {
//     padding: 0,
//   },
//   backdrop: {
//     zIndex: theme.zIndex.drawer + 1,
//     color: "#fff",
//   },
//   header: {
//     display: "flex",
//     justifyContent: "space-between",
//     [theme.breakpoints.down("xs")]: {
//       minWidth: "10px",
//     },
//   },
// }));

// const Form = (props) => {
//   const dispatch = useDispatch();
//   const { id } = useParams();
//   const { users, loading } = useSelector((state) => state.users);
//   const user = users.find((user) => user._id === id);
//   const { className, edit, ...rest } = props;
//   const classes = useStyles();

//   const [message, setAlertMessage] = useState("");
//   const [severity, setAlertSeverity] = useState("");
//   const [tab, setTab] = useState(1);
//   // const [userLevels, setUserLevels] = useState("");
//   // const [points, setPoints] = useState(0);
//   const [open, setOpen] = useState(false);

//   const handleChangeTab = (event, newValue) => {
//     setTab(newValue);
//   };

//   const handleClose = () => {
//     setOpen(false);
//   };

//   const formik = useFormik({
//     initialValues: {
//       fullName: "",
//       phone: "",
//       password: "",
//       passwordConfirm: "",
//       avatar: "",
//       learningDuration: "",
//       country: "",
//     },
//     validationSchema: !id
//       ? Yup.object().shape({
//           fullName: Yup.string().required(),
//           email: Yup.string().required(),
//           phone: Yup.number().required(),
//           password: Yup.string().required(),
//         })
//       : "",
//     onSubmit: (values) => {
//       console.log(values);
//       dispatch(createUser(values)).then((res) => {
//         console.log(res);
//       });
//     },
//   });

//   return (
//     <div>
//       <Card
//         {...rest}
//         className={clsx(classes.root, className)}
//         component="form"
//         onSubmit={formik.handleSubmit}
//       >
//         <TabContext value={tab}>
//           <div className={classes.header}>
//             <TabList
//               scrollButtons="auto"
//               variant="scrollable"
//               className={classes.header}
//               onChange={handleChangeTab}
//               aria-label="simple tabs example"
//               indicatorColor="primary"
//               textColor="primary"
//             >
//               <Tab label="Basics" value={1} />
//             </TabList>
//             <div>
//               <IconButton
//                 className={classes.signOutButton}
//                 color="inherit"
//                 // onClick={console.log("switched")}
//               >
//                 <AccountCircleIcon style={{ display: edit ? "" : "none" }} />
//                 <span style={{ fontSize: "12px", fontWeight: "bold" }}>
//                   LOGIN AS USER
//                 </span>
//               </IconButton>
//             </div>
//           </div>
//           <TabPanel value={1}>
//             {/* <form autoComplete="off" noValidate onSubmit={formik.handleSubmit}> */}
//             <CardHeader subheader="The information can be edited" />
//             <Divider />
//             <CardContent>
//               <Grid container spacing={3}>
//                 <Grid item md={3} xs={12}>
//                   <TextField
//                     error={Boolean(
//                       formik.touched.fullName && formik.errors.fullName
//                     )}
//                     helperText={
//                       formik.touched.fullName && formik.errors.fullName
//                     }
//                     fullWidth
//                     autoComplete="none"
//                     label="Full name"
//                     name="fullName"
//                     variant="outlined"
//                     onChange={formik.handleChange}
//                     onBlur={formik.handleBlur}
//                     value={formik.values.fullName}
//                   />
//                 </Grid>

//                 {/* <Grid item md={3} xs={12}>
//                     <TextField
//                       error={Boolean(
//                         formik.touched.email && formik.errors.email
//                       )}
//                       helperText={formik.touched.email && formik.errors.email}
//                       fullWidth
//                       label="Email Address"
//                       name="email"
//                       variant="outlined"
//                       onChange={formik.handleChange}
//                       onBlur={formik.handleBlur}
//                       value={formik.values.email}
//                     />
//                   </Grid> */}
//                 <Grid item md={3} xs={12}>
//                   <TextField
//                     fullWidth
//                     error={Boolean(formik.touched.phone && formik.errors.phone)}
//                     helperText={formik.touched.phone && formik.errors.phone}
//                     autoComplete="phone"
//                     label="Phone Number"
//                     name="phone"
//                     type="number"
//                     variant="outlined"
//                     onChange={formik.handleChange}
//                     onBlur={formik.handleBlur}
//                     value={formik.values.phone}
//                   />
//                 </Grid>
//                 <Grid item md={3} xs={12}>
//                   <TextField
//                     fullWidth
//                     error={Boolean(
//                       formik.touched.country && formik.errors.country
//                     )}
//                     helperText={formik.touched.country && formik.errors.country}
//                     label="Country"
//                     name="country"
//                     type="text"
//                     variant="outlined"
//                     onChange={formik.handleChange}
//                     onBlur={formik.handleBlur}
//                     value={formik.values.country}
//                   />
//                 </Grid>
//                 <Grid item md={3} xs={12}>
//                   <TextField
//                     error={Boolean(
//                       formik.touched.password && formik.errors.password
//                     )}
//                     helperText={
//                       formik.touched.password && formik.errors.password
//                     }
//                     fullWidth
//                     label="password"
//                     name="password"
//                     autoComplete="none"
//                     variant="outlined"
//                     type="password"
//                     onChange={formik.handleChange}
//                     onBlur={formik.handleBlur}
//                     value={formik.values.password}
//                   />
//                 </Grid>
//                 <Grid item md={3} xs={12}>
//                   <TextField
//                     error={Boolean(
//                       formik.touched.passwordConfirm &&
//                         formik.errors.passwordConfirm
//                     )}
//                     helperText={
//                       formik.touched.passwordConfirm &&
//                       formik.errors.passwordConfirm
//                     }
//                     fullWidth
//                     label="password Confirm"
//                     name="passwordConfirm"
//                     variant="outlined"
//                     type="password"
//                     onChange={formik.handleChange}
//                     onBlur={formik.handleBlur}
//                     value={formik.values.passwordConfirm}
//                   />
//                 </Grid>
//                 <Grid item md={3} xs={12}>
//                   <TextField
//                     error={Boolean(
//                       formik.touched.avatar && formik.errors.avatar
//                     )}
//                     helperText={formik.touched.avatar && formik.errors.avatar}
//                     fullWidth
//                     name="avatar"
//                     variant="outlined"
//                     type="file"
//                     onChange={formik.handleChange}
//                     onBlur={formik.handleBlur}
//                     defaultValue=""
//                   />
//                 </Grid>
//                 <Grid item md={3} xs={12}>
//                   <TextField
//                     error={Boolean(
//                       formik.touched.learningDuration &&
//                         formik.errors.learningDuration
//                     )}
//                     helperText={
//                       formik.touched.learningDuration &&
//                       formik.errors.learningDuration
//                     }
//                     fullWidth
//                     Label="Learning Duration"
//                     name="learningDuration"
//                     variant="outlined"
//                     type="number"
//                     onChange={formik.handleChange}
//                     onBlur={formik.handleBlur}
//                     defaultValue=""
//                     value={formik.values.learningDuration}
//                   />
//                 </Grid>
//               </Grid>
//             </CardContent>
//             <Divider />
//             <CardActions>
//               <Button color="primary" variant="contained" type="submit">
//                 Create User
//               </Button>
//             </CardActions>
//             {/* </form> */}
//           </TabPanel>
//         </TabContext>
//       </Card>
//       <SnackBar
//         open={open}
//         message={message}
//         severity={severity}
//         handleClose={handleClose}
//       />
//     </div>
//   );
// };

// Form.propTypes = {
//   className: PropTypes.string,
//   handleCloseLoading: PropTypes.func,
// };

// export default Form;

// @flow
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from "@material-ui/core";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import SnackBar from "../../../components/SnackBar";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  getAllUsers,
  createUser,
  getUser,
  updateUser,
} from "../../../redux/slices/users";
import validateObj from "helpers/validateObj";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  content: {
    padding: 0,
  },
}));

const Form = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { className, edit, ...rest } = props;
  const classes = useStyles();

  const [message, setAlertMessage] = useState("Error");
  const [severity, setAlertSeverity] = useState("error");
  const [open, setOpen] = React.useState(false);
  const { loading, users } = useSelector((state) => state.users);

  const user = users.find((el) => el._id === id);

  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  //validations
  const validationOnCreate = Yup.object().shape({
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    phone: Yup.string()
      .min(8, "Must be at least 8 digits")
      .required("Required"),
    password: Yup.string()
      .required("Password is required")
      .min(5, "Your password is too short.")
      .matches(/[a-zA-Z]/, "Password can only contain Latin letters."),
    passwordConfirm: Yup.string()
      .required("Confirm password is required")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
    learningDuration: Yup.number().required("learning duration is required"),
  });
  const validationOnUpdate = Yup.object().shape({});

  //submit forms
  function onSubmit(values, { setSubmitting }) {
    if (id) {
      //UPDATE
      const validValues = validateObj(user, values);
      if (Object.keys(validValues).length !== 0) {
        dispatch(updateUser({ id, ...validValues })).then((res) => {
          if (res) {
            if (res.error) {
              setAlertMessage(res.payload.data.message);
              setAlertSeverity("error");
              handleClick();
            } else {
              dispatch(getAllUsers(""));
              setAlertMessage("User Updated Successfully");
              setAlertSeverity("success");
              handleClick();
              history.push("/users/students");
            }
          } else {
            handleClick();
          }
        });
      }
    } else {
      //CREATE
      dispatch(createUser(values)).then((res) => {
        if (res) {
          if (res.error) {
            setAlertMessage(res.payload.data.message);
            setAlertSeverity("error");
            handleClick();
          } else {
            dispatch(getAllUsers(""));
            setAlertMessage("User Created Successfully");
            setAlertSeverity("success");
            handleClick();
            history.push("/users/students");
          }
        } else {
          handleClick();
        }
      });
    }
  }

  return (
    <div>
      <Card {...rest} className={clsx(classes.root, className)}>
        <Formik
          initialValues={{
            firstName: id ? user?.firstName : "",
            lastName: id ? user?.lastName : "",
            phone: id ? user?.phone : "",
            password: id ? user?.password : "",
            passwordConfirm: id ? user?.passwordConfirm : "",
            avatar: "",
            learningDuration: id ? user?.learningDuration : "",
            country: id ? user?.country : "",
          }}
          validationSchema={id ? validationOnUpdate : validationOnCreate}
          onSubmit={onSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <CardHeader subheader="User Form" />
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      error={Boolean(touched.firstName && errors.firstName)}
                      helperText={touched.firstName && errors.firstName}
                      name="firstName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.firstName}
                      variant="outlined"
                      label="First Name"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      error={Boolean(touched.lastName && errors.lastName)}
                      helperText={touched.lastName && errors.lastName}
                      name="lastName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.lastName}
                      variant="outlined"
                      label="Last Name"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      error={Boolean(touched.country && errors.country)}
                      helperText={touched.country && errors.country}
                      name="country"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.country}
                      variant="outlined"
                      label="Country"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      error={Boolean(touched.phone && errors.phone)}
                      helperText={touched.phone && errors.phone}
                      name="phone"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.phone}
                      variant="outlined"
                      label="Phone"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      error={Boolean(touched.password && errors.password)}
                      helperText={touched.password && errors.password}
                      name="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="password"
                      value={values.password}
                      variant="outlined"
                      label="Password"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      error={Boolean(
                        touched.passwordConfirm && errors.passwordConfirm
                      )}
                      helperText={
                        touched.passwordConfirm && errors.passwordConfirm
                      }
                      name="passwordConfirm"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="password"
                      value={values.passwordConfirm}
                      variant="outlined"
                      label="passwordConfirm"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      error={Boolean(touched.avatar && errors.avatar)}
                      helperText={touched.avatar && errors.avatar}
                      fullWidth
                      name="avatar"
                      variant="outlined"
                      type="file"
                      onChange={(e) =>
                        setFieldValue("avatar", e.target.files[0])
                      }
                      onBlur={handleBlur}
                      defaultValue=""
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      error={Boolean(
                        touched.learningDuration && errors.learningDuration
                      )}
                      helperText={
                        touched.learningDuration && errors.learningDuration
                      }
                      name="learningDuration"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="number"
                      value={values.learningDuration}
                      variant="outlined"
                      label="Learning Duration"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {loading === "loading" && <h2>Loading...</h2>}
                    <Divider />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <CardActions>
                <Button color="primary" type="submit" variant="contained">
                  {!id ? "Create User" : "Update User"}
                </Button>
              </CardActions>
            </form>
          )}
        </Formik>
      </Card>

      <SnackBar
        open={open}
        message={message}
        severity={severity}
        handleClose={handleClose}
      />
    </div>
  );
};

Form.propTypes = {
  className: PropTypes.string,
  edit: PropTypes.bool,
  handleCloseLoading: PropTypes.func,
  loading: PropTypes.bool,
};

export default Form;
