import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getData,
  createOne,
  getOne,
  deleteOne,
  updateOne,
} from "../../services/api";

export const getNotifications = createAsyncThunk(
  "notifications/getNotif",
  async (filter, { getState, rejectWithValue }) => {
    try {
      const { data } = await getData("notifications", filter);
      console.log(data);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);
// insert notif
export const sendNotif = createAsyncThunk(
  "notifications/insertNotif",
  async (notifications, { rejectWithValue }) => {
    try {
      const { data } = await createOne("notifications", notifications);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);
// update notif
export const updateNotif = createAsyncThunk(
  "notifications/updateNotif",
  async (data, { rejectWithValue, thunkAPI }) => {
    const { id, ...notif } = data;
    try {
      const { data } = await updateOne("notifications", id, notif);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);
// get one notif
export const getOneNotif = createAsyncThunk(
  "notif/getOneNotif",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await getOne("notifications", id);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

// delete language
export const deleteNotif = createAsyncThunk(
  "notif/deleteNotifications",
  async (id, { rejectWithValue }) => {
    try {
      await deleteOne("notifications", id);
      return id;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);
const initialState = {
  notifications: [],
  notification: {},
  error: null,
  loading: "",
};

const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {},
  extraReducers: {
    [getNotifications.pending]: (state, { payload }) => {
      state.loading = "loading";
    },
    [getNotifications.fulfilled]: (state, { payload }) => {
      state.loading = "success";
      state.notifications = payload.payload.notifications;
    },
    [getNotifications.rejected]: (state, { payload }) => {
      state.error = payload;
      state.loading = "failed";
    },
    //send notif
    [sendNotif.pending]: (state, { payload }) => {
      state.loading = "loading";
    },
    [sendNotif.fulfilled]: (state, { payload }) => {
      state.loading = "success";
      // state.notifications.push(payload.payload.notification);
    },
    [sendNotif.rejected]: (state, { payload }) => {
      state.error = payload;
      state.loading = "failed";
    },

    // update notif
    [updateNotif.pending]: (state, { payload }) => {
      state.loading = "loading";
    },
    [updateNotif.fulfilled]: (state, { payload }) => {
      state.loading = "success";
    },
    [updateNotif.rejected]: (state, { payload }) => {
      state.error = payload;
      state.loading = "failed";
    },

    //get one notif
    [getOneNotif.pending]: (state, { payload }) => {
      state.loading = "loading";
    },
    [getOneNotif.fulfilled]: (state, { payload }) => {
      state.loading = "success";
      state.notification.push(payload.notification.payload);
    },
    [getOneNotif.rejected]: (state, { payload }) => {
      state.error = payload;
      state.loading = "failed";
    },

    // delete notif
    [deleteNotif.fulfilled]: (state, { payload }) => {
      state.loading = "success";
      const notifs = state.notifications.filter((el) => el._id !== payload);
      state.notifications = notifs;
    },
    [deleteNotif.rejected]: (state, { payload }) => {
      state.error = payload;
      state.loading = "failed";
    },
  },
});

export default notificationsSlice.reducer;
