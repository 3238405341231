const findQuestions = (questions, questionBlocks, code, setQuestions) => {
  if (code === "All") {
    return setQuestions(questions);
  }
  let newQuestions = [];
  for (let i = 0; i < questions.length; i++) {
    for (let j = 0; j < questions[i].questionBlocks.length; j++) {
      //   console.log(questions[i].questionBlocks[j]);
      let qtnBlock = questionBlocks.find(
        (el) => el._id === questions[i].questionBlocks[j]
      );
      if (qtnBlock?.questionBlockTypeId.code === code) {
        newQuestions.push(questions[i]);
      }
    }
  }
  setQuestions(newQuestions);

  return newQuestions;
};

export default findQuestions;
