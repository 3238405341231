import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { serialize } from "object-to-formdata";
import {
  getData,
  getOne,
  createOne,
  updateOne,
  deleteOne,
} from "../../services/api";

export const getAdmins = createAsyncThunk(
  "admins/gatAdmns",
  async (filter, { getState, rejectWithValue }) => {
    try {
      const { data } = await getData("admins", filter);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

// create admin
export const createAdmin = createAsyncThunk(
  "admin/createAdmn",
  async (admin, { rejectWithValue, thunkAPI }) => {
    try {
      const { data } = await createOne("admins", admin);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const getAdmin = createAsyncThunk(
  "admin/getAdmn",
  async (id, { getState, rejectWithValue }) => {
    try {
      const { data } = await getOne("admins", id);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);
export const updateAdmin = createAsyncThunk(
  "admin/updateAdmn",
  async (data, { getState, rejectWithValue }) => {
    const { id, ...admin } = data;
    try {
      const { data } = await updateOne("admins", id, admin);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);
export const deleteAdmin = createAsyncThunk(
  "admin/delete",
  async (id, { getState, rejectWithValue }) => {
    try {
      const { data } = await deleteOne("admins", id);
      return id;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const initialState = {
  admins: [],
  admin: {},
  isSuccess: false,
  error: null,
  loading: "",
};

const adminsSlice = createSlice({
  name: "admins",
  initialState,
  reducers: {},
  extraReducers: {
    [getAdmins.pending]: (state, { payload }) => {
      state.loading = "loading";
    },
    [getAdmins.fulfilled]: (state, { payload }) => {
      state.loading = "success";
      state.admins = payload.payload.admins;
      state.isSuccess = true;
    },
    [getAdmins.rejected]: (state, { payload }) => {
      state.error = payload;
      state.loading = "failed";
      state.isSuccess = false;
    },

    [getAdmin.pending]: (state, { payload }) => {
      state.loading = "loading";
    },
    [getAdmin.fulfilled]: (state, { payload }) => {
      state.loading = "success";
      state.admin = payload.payload.admin;
      state.isSuccess = true;
    },
    [getAdmin.rejected]: (state, { payload }) => {
      state.error = payload;
      state.loading = "failed";
      state.isSuccess = false;
    },

    // update admin
    [updateAdmin.pending]: (state, { payload }) => {
      state.loading = "loading";
    },
    [updateAdmin.fulfilled]: (state, { payload }) => {
      state.loading = "success";
    },
    [updateAdmin.rejected]: (state, { payload }) => {
      state.error = payload;
      state.loading = "failed";
    },
    // delete admin
    [deleteAdmin.fulfilled]: (state, { payload }) => {
      state.loading = "success";
      const admins = state.admins.filter((el) => el._id !== payload);
      state.admins = admins;
    },
    [deleteAdmin.rejected]: (state, { payload }) => {
      state.error = payload;
      state.loading = "failed";
    },
  },
});

export default adminsSlice.reducer;
