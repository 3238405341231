import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getData, createOne, deleteOne, updateOne } from "../../services/api";
// get q type
export const getQuestionsTypes = createAsyncThunk(
  "questions/getQuestionsTypes",
  async (filter, { getState, rejectWithValue }) => {
    try {
      const { data } = await getData("question-types", filter);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);
//create q type
export const createQuestionType = createAsyncThunk(
  "Question/insertQuestionTypes",
  async (questionType, { rejectWithValue }) => {
    try {
      const { data } = await createOne("question-types", questionType);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);
// update exerciceType
export const updateQuestionType = createAsyncThunk(
  "ex/updateQuestType",
  async (data, { rejectWithValue, thunkAPI }) => {
    const { id, ...questionType } = data;
    try {
      const { data } = await updateOne("question-types", id, questionType);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);
// delete exercice
export const deleteQuestionType = createAsyncThunk(
  "exercice/deleteQuestiontypes",
  async (id, { rejectWithValue }) => {
    try {
      await deleteOne("question-types", id);
      return id;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const initialState = {
  questionTypes: [],
  error: null,
  loading: "",
};

const questionsTypesSlice = createSlice({
  name: "questionsTypes",
  initialState,
  reducers: {},
  extraReducers: {
    // get question type
    [getQuestionsTypes.pending]: (state, { payload }) => {
      state.loading = "loading";
    },
    [getQuestionsTypes.fulfilled]: (state, { payload }) => {
      state.loading = "success";
      state.questionTypes = payload.payload.questionTypes;
    },
    [getQuestionsTypes.rejected]: (state, { payload }) => {
      state.error = payload;
      state.loading = "failed";
    },
    // update ex type
    [updateQuestionType.pending]: (state, { payload }) => {
      state.loading = "loading";
    },
    [updateQuestionType.fulfilled]: (state, { payload }) => {
      state.loading = "success";
    },
    [updateQuestionType.rejected]: (state, { payload }) => {
      state.error = payload;
      state.loading = "failed";
    },
    // insert q type
    [createQuestionType.pending]: (state, { payload }) => {
      state.loading = "loading";
    },
    [createQuestionType.fulfilled]: (state, { payload }) => {
      state.loading = "success";
      state.questionTypes.push(payload.payload.questionTypes);
    },
    [createQuestionType.rejected]: (state, { payload }) => {
      state.error = payload;
      state.loading = "failed";
    },
    // delete exercice
    [deleteQuestionType.fulfilled]: (state, { payload }) => {
      state.loading = "success";
      const questionType = state.questionTypes.filter(
        (el) => el._id !== payload
      );
      state.questionTypes = questionType;
    },
    [deleteQuestionType.rejected]: (state, { payload }) => {
      state.error = payload;
      state.loading = "failed";
    },
  },
});

// export const {} = exerciceTypeSlice.actions;
export default questionsTypesSlice.reducer;
