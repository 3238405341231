import axios from "axios";
const baseUrl = process.env.REACT_APP_FRONT_USERS_URL;

export const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

const api = axios.create({
  baseURL: process.env.REACT_APP_FRONT_USERS_URL,
  headers,
});

//request interceptor to add the auth token header to requests
api.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("token");
    if (accessToken) {
      config.headers["Authorization"] = "Bearer " + accessToken;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    let refreshToken = localStorage.getItem("refreshToken");
    // console.log(error.response);
    if (
      refreshToken &&
      error.response.status === 401
      // error.response.data.message == "jwt expired"
      // !originalRequest._retry
    ) {
      originalRequest.sent = true;
      // originalRequest._retry = true;
      return api
        .post(`/auth/refresh`, { refreshToken: refreshToken })
        .then((res) => {
          if (res.status === 200) {
            localStorage.setItem("token", res.data.accessToken);
            originalRequest.headers["Authorization"] =
              "Bearer " + res.data.accessToken;
            console.log("Access token refreshed!");
            return api(originalRequest);
          }
        });
    }
    return Promise.reject(error);
  }
);

export const getData = (url, filter) => api.get(`/${url}${filter}`);
export const getOne = (url, id) => api.get(`/${url}/${id}`);
export const updateOne = (url, id, item) => api.put(`/${url}/${id}`, item);
export const updateOrder = (orders, item) =>
  api.put(`/${item}/update-order`, orders);
export const createOne = (url, data) => api.post(`/${url}`, data);
export const deleteOne = (url, id) => api.delete(`/${url}/${id}`);

export default api;
