import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getData,
  deleteOne,
  createOne,
  getOne,
  updateOne,
} from "../../services/api";
import { serialize } from "object-to-formdata";

export const getKeywords = createAsyncThunk(
  "keywords/getKeys",
  async (filter, { getState, rejectWithValue }) => {
    try {
      const { data } = await getData("keywords", filter);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);
// get one keyword
export const getKeyword = createAsyncThunk(
  "keyword/getKeyword",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await getOne("keywords", id);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);
// delete keyword
export const deleteKeyword = createAsyncThunk(
  "keyword/deleteKey",
  async (id, { rejectWithValue }) => {
    try {
      await deleteOne("keywords", id);
      return id;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

// insert keywords
export const insertKeywords = createAsyncThunk(
  "keywords/insertkey",
  async (keywords, { rejectWithValue, thunkAPI }) => {
    try {
      const { data } = await createOne(
        "keywords",
        serialize(keywords, { indices: true })
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

// update keywords
export const updateKeyword = createAsyncThunk(
  "keywords/updateKey",
  async (data, { rejectWithValue, thunkAPI }) => {
    const { id, ...val } = data;
    // console.log(data[0]);
    try {
      const { data } = await updateOne(
        "keywords",
        id,
        serialize(val, { indices: true })
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const initialState = {
  keywords: [],
  keyword: {},
  error: false,
  loading: "",
};

const keywordsSlice = createSlice({
  name: "keywords",
  initialState,
  reducers: {},
  extraReducers: {
    // get keywords
    [getKeywords.pending]: (state, { payload }) => {
      state.loading = "loading";
    },
    [getKeywords.fulfilled]: (state, { payload }) => {
      state.loading = "success";
      state.keywords = payload.payload.keywords;
    },
    [getKeywords.rejected]: (state, { payload }) => {
      state.error = payload;
      state.loading = "failed";
    },

    // update key
    [updateKeyword.pending]: (state, { payload }) => {
      state.loading = "loading";
    },
    [updateKeyword.fulfilled]: (state, { payload }) => {
      state.loading = "success";
    },
    [updateKeyword.rejected]: (state, { payload }) => {
      state.error = payload;
      state.loading = "failed";
    },
    // get one keyword
    [getKeyword.pending]: (state, { payload }) => {
      state.loading = "loading";
    },
    [getKeyword.fulfilled]: (state, { payload }) => {
      state.loading = "success";
      state.keyword = payload.payload.keyword;
    },
    [getKeyword.rejected]: (state, { payload }) => {
      state.error = payload;
      state.loading = "failed";
    },
    //insert keywords
    [insertKeywords.pending]: (state, { payload }) => {
      state.loading = "loading";
    },
    [insertKeywords.fulfilled]: (state, { payload }) => {
      state.loading = "success";
      state.keywords.push(payload.payload.keywords);
    },
    [insertKeywords.rejected]: (state, { payload }) => {
      state.error = true;
      state.loading = "failed";
    },
    // delete language
    [deleteKeyword.fulfilled]: (state, { payload }) => {
      state.loading = "success";
      const keys = state.keywords.filter((el) => el._id !== payload);
      state.keywords = keys;
    },
    [deleteKeyword.rejected]: (state, { payload }) => {
      state.error = payload;
      state.loading = "failed";
    },
  },
});

// export const { checkOrder } = levelsSlice.actions;
export default keywordsSlice.reducer;
