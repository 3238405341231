import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import Toolbar from "../../components/Toolbar/Toolbar";
import Table from "../../components/Table/Table";
import Collapse from "@material-ui/core/Collapse";
import Columns from "../../components/Columns";
import Filter from "../../components/Filter/Filter";
import { deleteUser, getAllUsers } from "../../redux/slices/users";
import { useDispatch, useSelector } from "react-redux";
import TablePagination from "@material-ui/core/TablePagination";
import Pagination from "@material-ui/lab/Pagination";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));

const columnsList = [
  {
    accessor: "_id",
    name: "id",
    label: "Id",
    width: "7%",
    show: true,
    sortable: true,
    type: "userSwitch",
  },

  {
    accessor: "firstName",
    name: "name",
    label: "First Name",
    width: "14%",
    show: true,
    sortable: true,
  },
  {
    accessor: "lastName",
    name: "name",
    label: "Last Name",
    width: "20%",
    show: true,
    sortable: true,
  },
  {
    accessor: "phone",
    label: "Phone",
    width: "20%",
    show: true,
    type: "fieldValue",
  },

  {
    accessor: "createdAt",
    name: "createdAt",
    label: "Created At",
    width: "20%",
    show: true,
    sortable: true,
    type: "date",
  },
  {
    accessor: "updatedAt",
    name: "updatedAt",
    label: "Updated At",
    width: "20%",
    show: true,
    sortable: true,
    type: "date",
  },
];
const fieldSearchable = [];
const fieldFilterable = [
  { name: "firstName", label: "firstName", type: "text" },
  { name: "lastName", label: "lastName", type: "text" },
  { name: "phone", label: "Phone", type: "number" },
  { name: "email", label: "Email", type: "text" },
  { name: "createdAt", label: "Created At", type: "dateRange" },
  { name: "updatedAt", label: "Updated At", type: "dateRange" },
];

const UserList = () => {
  const { users, loading, meta } = useSelector((state) => state.users);

  console.log(meta);
  const [search, searchChange] = useState("");
  const [filters, filtersChange] = useState({});
  const [columns, columnsChange] = useState(columnsList);
  const [sort, sortChange] = useState({ accessor: "id", order: "desc" });
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [columnsOpen, setColumnsOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [data, setData] = useState([]);

  const dispatch = useDispatch();
  useEffect(() => {
    // if (users?.length === 0) {
    dispatch(getAllUsers(`?limit=${rowsPerPage}&page=${page}`));
    // }
  }, [dispatch, page, rowsPerPage]);

  const toggleFilters = () => {
    setFiltersOpen(!filtersOpen);
  };
  const toggleColumns = () => {
    setColumnsOpen(!columnsOpen);
  };
  const handleSortChange = (accessor) => {
    sortChange({
      accessor: accessor,
      order: sort.order === "asc" ? "desc" : "asc",
    });
  };

  const handleChangePage = (event, newPage) => {
    // setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    // setRowsPerPage(parseInt(event.target.value, 10));
    // setPage(1);
  };

  const handleChange = (event) => {
    setRowsPerPage(event.target.value);
  };
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Toolbar
        toggleFilters={toggleFilters}
        toggleColumns={toggleColumns}
        searchChange={searchChange}
        pageLink={"/users/students"}
        searchMessage={"Search (ID, Name)"}
      />
      <div className={classes.content}>
        <Collapse in={columnsOpen}>
          <Columns columnsChange={columnsChange} columns={columns} />
        </Collapse>
        <Collapse in={filtersOpen}>
          <Filter
            fields={fieldFilterable}
            values={filters}
            filtersChange={filtersChange}
            handelFilter={getAllUsers}
          />
        </Collapse>
        <div
          style={{
            padding: "20px",
            border: "1px solid #dadada",
            textAlign: "center",
            marginBottom: "10px",
          }}
        >
          <h3>{meta?.totalDocs} users</h3>
        </div>
        <Table
          loading={loading}
          columns={columns}
          data={users}
          deleteItem={deleteUser}
          handleSortChange={handleSortChange}
          sort={sort}
          handlePageChange={handleChangePage}
          page={page}
          tableService={""}
          rowsPerPage={rowsPerPage}
          handleRowsPerPageChange={handleChangeRowsPerPage}
          pageLink={"/users/students"}
        />

        <div
          style={{
            marginTop: "15px",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FormControl variant="outlined" style={{ width: "50%" }}>
            <InputLabel id="RowsPerPage">RowsPerPage</InputLabel>
            <Select
              labelId="RowsPerPage"
              id="RowsPerPage"
              name="RowsPerPage"
              label="RowsPerPage"
              defaultValue={rowsPerPage}
              onChange={(e) => {
                handleChange(e);
              }}
            >
              <MenuItem value="10">10 </MenuItem>
              <MenuItem value="25">25 </MenuItem>
              <MenuItem value="50">50 </MenuItem>
              <MenuItem value="100">100 </MenuItem>
              <MenuItem value="150">150 </MenuItem>
            </Select>
          </FormControl>
          <Pagination
            width="50%"
            style={{ width: "100%" }}
            color="primary"
            count={meta?.totalPages}
            onChange={(event, value) => {
              setPage(value);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default UserList;
